<template>
<!--
    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4 text-center">
-->
    <div class="signin-body">
        <div class="form-signin">
        <!--
            <img class="mb-4" src="../../assets/logos/bootstrap/bootstrap-solid.svg" alt="" width="72" height="72">
        -->
            <img class="mb-4" src="@/assets/logo.png" alt="" width="160">
            <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>

            <!-- Display server error messages, from server validation -->
            <!-- Diplay alert message received from backend -->
            <AltertMessage
                v-bind:data="formUtilsComp.state.form.response"
            ></AltertMessage>

            <!-- Display server exceptions, from database -->
            <ExceptionMessage
                v-bind:exception="formUtilsComp.state.form.exception"
            ></ExceptionMessage>

            <div class="form-group mb-3">
                <input
                    v-model="authComp.state.form.body.email"
                    @keyup.enter="loginComp()"
                    type="email"
                    id="inputEmail"
                    class="form-control rounded-4"
                    v-bind:class="formUtilsComp.isEmailValid(authComp.state.form.body.email) ? 'is-valid' : 'is-invalid'"
                    placeholder="Email address"
                    required autofocus
                >
                <div v-if="!formUtilsComp.isEmailValid(authComp.state.form.body.email)" class="text-info text-sm">Please enter a valid email address</div>
                <!-- Diplay alert message received from backend -->
                <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.email"></ValidationErrors>
            </div>

            <div class="form-group mb-3">
                <input
                    v-model="authComp.state.form.body.password"
                    :type="authComp.state.form.show_password ? 'text' : 'password'"
                    @keyup.enter="loginComp()"
                    id="inputPassword"
                    class="form-control rounded-4"
                    v-bind:class="formUtilsComp.isPasswordEmpty(authComp.state.form.body.password) ? 'is-invalid' : 'is-valid'"
                    placeholder="Password" required
                >
                <div v-if="formUtilsComp.isPasswordEmpty(authComp.state.form.body.password)" class="text-info text-sm">Please enter your password</div>
                <!-- Diplay alert message received from backend -->
                <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.password"></ValidationErrors>
            </div>

            <div class="form-group">
                <div class="checkbox mb-1">
                    <label>
                        <input
                            v-model="authComp.state.form.show_password"
                            type="checkbox"
                            value="show-password"
                        > Show password
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox mb-1">
                    <label>
                        <input
                            v-model="authComp.state.form.remember_me"
                            type="checkbox"
                            value="remember-me"
                        > Remember me
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox mb-3">
                    <label>
                        <input
                            v-model="authComp.state.form.fetchUser"
                            type="checkbox"
                            value="fetch-user"
                        > Fetch User
                    </label>
                </div>
            </div>

<!--
            <button
                @click="loginDefault"
                class="btn btn-lg btn-primary btn-block"
            >Force Sign in</button>
-->

<!--
            <button
                v-if="formUtilsComp.isEmailValid(authComp.state.form.body.email)"
                @click="loginDefault"
                class="btn btn-lg btn-primary btn-block"
            >Sign in</button>
-->

            <button
                v-if="formUtilsComp.isEmailValid(authComp.state.form.body.email)"
                @click="loginComp"
                class="btn btn-lg btn-primary btn-block"
            >Sign in</button>




            <hr>
            <div>
                <router-link  :to="{ name : 'forgot-password' }">Forgot your password ?</router-link>
            </div>
            <div class="mt-3">
                <router-link  :to="{ name : 'auth-register' }">Go to register</router-link>
            </div>

            <hr>



<!--
            <button
                @click="loginDefault"
                class="btn btn-lg btn-primary btn-block"
            >Sign in "Default"</button>

            <button
                @click="loginRedirect"
                class="btn btn-lg btn-secondary btn-block"
                type="submit"
            >Sign in "Redirect"</button>

            <button
                @click="loginThen"
                class="btn btn-lg btn-secondary btn-block"
                type="submit"
            >Sign in "Then"</button>

            <button
                @click="loginComp"
                class="btn btn-lg btn-secondary btn-block"
                type="submit"
            >Sign in "Comp"</button>

            <button
                @click="loginManual"
                class="btn btn-lg btn-secondary btn-block"
                type="submit"
            >Sign in "Manual"</button>

            <p class="mt-5 mb-3 text-muted">&copy; 2017-2020</p>
-->


        </div>

    </div>
<!--
    </main>
-->
</template>

<script>
// @ is an alias to /src

import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useAuth } from '@websanova/vue-auth/src/v3.js';

// import composables files
import useAuthComp from '@/composables/useAuthComp.js';
import useFormUtilsComp from '@/composables/useFormUtilsComp.js';

// import informations and errors messages components
import AltertMessage from '@/components/messages/altertMessage.vue'
import ExceptionMessage from '@/components/messages/exceptionMessage.vue'
import ValidationErrors from '@/components/messages/validationErrors.vue'

export default {

    name: 'Login',

    components : {
        AltertMessage,
        ValidationErrors,
        ExceptionMessage,
    },

    setup() {
        const auth     = useAuth();
        //const store    = useStore();
        const router   = useRouter();
        const authComp = useAuthComp();
        const formUtilsComp = useFormUtilsComp()

        onMounted(() => {
            //
        });

        function loginDefault() {
            auth
            .login({
                data: authComp.state.form.body,
                remember: authComp.state.form.remember_me ? '{"name": "Default"}' : null,
                fetchUser: authComp.state.form.fetchUser,
                staySignedIn: authComp.state.form.staySignedIn,
                redirect: '/'
            })
            .then((res) => {
                //auth.user(res.data);
                formUtilsComp.useMessages(res.data, 'alert-success')
            }, (err) => {
                formUtilsComp.useSetErrors(err.response.data)
            });
        }


        function loginRedirect() {
            auth
            .login({
                data: authComp.state.form.body,
                redirect: {name: 'user-account'},
                remember: authComp.state.form.remember_me ? '{"name": "Redirect"}' : null,
                fetchUser: authComp.state.form.fetchUser,
                staySignedIn: authComp.state.form.staySignedIn,
            })
            .then(null, (err) => {
                formUtilsComp.useSetErrors(err.response.data)
            });
        }


        function loginThen() {
            auth
            .login({
                data: authComp.state.form.body,
                redirect: null,
                fetchUser: true,
                staySignedIn: true,
            })
            //.then((res) => {
            .then(() => {
                if (authComp.state.form.remember_me) {
                    auth.remember(JSON.stringify({
                        name: auth.user().name
                    }));
                }

                router.push({name: 'user-account'});
            }, (err) => {
                formUtilsComp.useSetErrors(err.response.data)
            });
        }

        function loginComp() {
            authComp.login({
                body: authComp.state.form.body,
                remember: authComp.state.form.remember_me,
                fetchUser: authComp.state.form.fetchUser,
                staySignedIn: authComp.state.form.staySignedIn,
            })
            .then(null, (err) => {
                formUtilsComp.useSetErrors(err.response.data)
            });
        }

        function loginManual() {
            auth.token(null, 'manual', false);

            auth
            .user({
                id: 1,
                first_name: 'Manual',
                email: 'test@manual.com',
                type: 'user',
            });

            if (authComp.state.form.remember_me) {
                auth
                .remember(JSON.stringify({
                    name: auth.user().first_name
                }));
            }
            else {
                auth.unremember();
            }

            router.push({name: 'user-landing'});
        }

        // data returned to the template view
        return {
            authComp,
            formUtilsComp,
            loginThen,
            loginComp,
            loginManual,
            loginDefault,
            loginRedirect,
        }
    }
}
</script>

<style>
    @import '../../assets/css/signin.css';
</style>
